import { Box, Flex, Link } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { ExternalLink } from 'lucide-react'
import { Content } from '~/views/layout/Content.tsx'
import { TeamBillingOverview } from '~/views/team/TeamBillingOverview'
import { TeamBillingInvoices } from '~/views/team/TeamBillingInvoices'
import { config } from '~/config'
import { TeamBillingPayments } from '~/views/team/TeamBillingPayment'
import { TokenUsage } from '~/views/team/TokenUsage'

export function Billing() {
  return (
    <Content title="Billing">
      <Helmet>
        <title>
          Billing
        </title>
      </Helmet>
      { config.billingDebugMode && (
        <div className="fixed inset-x-0 top-0 z-50 flex items-center justify-center gap-2 bg-destructive py-1 text-destructive-foreground">
          Test Mode: Only
          <Link href="https://docs.stripe.com/testing?testing-method=card-numbers#cards" underline="hover" className="drak:text-green-800 flex items-center gap-1 text-green-300" target="_blank" rel="noreferrer noopener">
            test card numbers
            <ExternalLink className="size-4" />
          </Link>
          {' '}
          can be used for simulated transactions in this mode, and no actual charges will be incurred.
        </div>
      ) }
      <Box className="mx-auto w-full max-w-5xl">
        <Flex direction="column" gap="6">
          <TeamBillingOverview />
          <TeamBillingInvoices />
          <TeamBillingPayments />
          <TokenUsage />
        </Flex>
      </Box>
    </Content>
  )
}
