import { Card, Flex, Heading, Section, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { Content } from '~/views/layout/Content.tsx'
import { BasicInfo } from '~/views/project/BasicInfo.tsx'
import { useProjectStore } from '~/stores/project.ts'

export function ProjectConfig() {
  const projectStore = useProjectStore()

  return (
    <Content title="Configuration">
      <Helmet>
        <title>
          Configuration /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Basic Info</Heading>
            <Text size="2" color="gray">
              Connection info for the Project
            </Text>
          </Flex>
          <Card>
            <BasicInfo />
          </Card>
        </Flex>
      </Section>
    </Content>
  )
}
