import { Flex, Heading, Link } from '@radix-ui/themes'
import { ExternalLink, LeafIcon, RocketIcon } from 'lucide-react'
import { useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useMount } from 'react-use'
import { config } from '~/config'
import { apiBillingFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'
import { PlanCard } from '~/views/plans/PlanCard'
import { UpgradeModal } from '~/views/plans/UpgradeModal'
import { Content } from '~/views/layout/Content.tsx'
import { PlanFeatures } from '~/views/plans/PlanFeatures'

const planConfig: Record<string, any> = {
  Free: {
    name: 'Free',
    icon: LeafIcon,
    period: 'forever',
    description: 'Perfect for individuals and small projects',
    buttonVariant: 'outline',
    highlighted: false,
    limitations: [
      'Limited to basic features and models',
    ],
    buttonText: 'Get Started',
  },
  Growth: {
    name: 'Growth',
    icon: RocketIcon,
    period: '/month',
    description: 'Ideal for growing teams and businesses',
    buttonVariant: 'solid',
    highlighted: true,
    buttonText: 'Upgrade to Growth',
  },
}
export function Plans() {
  const teamStore = useTeamStore()
  const [currentPlan, setCurrentPlan] = useState('Free')
  const [plans, setPlans] = useState<Record<string, any>[]>([{ ...planConfig.Free }])
  const [loading, setLoading] = useState(true)
  const [upgradeModalOpen, setUpgradeModalOpen] = useState(false)
  const [upgradePlanInfo, setUpgradePlanInfo] = useState<Record<string, any>>({})
  const fetched = useRef(false)

  useMount(async () => {
    if (fetched.current)
      return
    fetched.current = true

    setLoading(true)
    let currentPlan = 'Free'
    let plans = []
    try {
      const current = await apiBillingFetch('/v1/plans/find', {
        params: {
          org_id: teamStore.currentTeam.id,
        },
      })
      if (current.data)
        currentPlan = current.data.name
    }
    catch (error) {}
    try {
      const all = await apiBillingFetch('/v1/plans')
      plans = all.data.filter((item: Record<string, any>) => ['Free', 'Growth'].includes(item.name))
    }
    catch (error) {}
    setLoading(false)
    setCurrentPlan(currentPlan)
    setPlans(plans)
  })
  const upgrade = (plan: Record<string, any>) => {
    setUpgradePlanInfo(plan)
    setUpgradeModalOpen(true)
  }
  const onUpgradeModalOpenChange = async (open: boolean) => {
    if (!open)
      setUpgradeModalOpen(false)
  }
  const genFeatures = (plan: Record<string, any>) => {
    const intl = new Intl.NumberFormat('en-US')
    if (plan.name === 'Growth') {
      return [
        `All Free plan features`,
        `Models: ${plan.models?.join(', ')}`,
        `Rate Limit: ${intl.format(5000)} RPM, ${intl.format(plan.tpm)} TPM`,
        `Realtime Whisper (Private Preview) for Stateful Serverless GPU`,
        `30-day API Access Log`,
        `Priority support via Email and Slack`,
      ]
    }
    return [
      `Unlimited seats`,
      `Chat Completion endpoint`,
      `Geo API: ${plan.zones} regions`,
      `Model: ${plan.models?.join(', ')}`,
      `Rate Limit: 6 RPM, ${intl.format(plan.tpm)} TPM`,
      `Unlimited function duration, executions, and invocations`,
      `Geo Deploy: ${plan.zones} regions`,
      `1-day API Access Log`,
      `LLM Latency Breakdown`,
      `Email and community support`,
      `99.00% SLA`,
    ]
  }
  const intl = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
  })
  const renderPlans = plans.filter((item) => {
    if (item.name === 'Free' && currentPlan === 'Growth')
      return false
    return true
  })
  return (
    <Content title="Plans">
      <Helmet>
        <title>
          Plans
        </title>
      </Helmet>
      { config.billingDebugMode && (
        <div className="fixed inset-x-0 top-0 z-50 flex items-center justify-center gap-2 bg-destructive py-1 text-destructive-foreground">
          Test Mode: Only
          <Link href="https://docs.stripe.com/testing?testing-method=card-numbers#cards" underline="hover" className="drak:text-green-800 flex items-center gap-1 text-green-300" target="_blank" rel="noreferrer noopener">
            test card numbers
            <ExternalLink className="size-4" />
          </Link>
          {' '}
          can be used for simulated transactions in this mode, and no actual charges will be incurred.
        </div>
      ) }
      <Flex direction="column" gap="6" className="mx-auto w-full max-w-7xl">
        <Flex direction={{ initial: 'column', lg: 'row' }} justify={{ lg: 'between' }} gap="6" py="4">
          <div className="w-full lg:max-w-60">
            <Flex direction="column" gap="3">
              <Heading size="4">Team plans</Heading>
            </Flex>
          </div>
          <div className="w-full">
            <div className="flex flex-wrap gap-4">
              {renderPlans.map((item) => {
                const config = planConfig[item.name]
                return (
                  <PlanCard
                    key={item.name}
                    loading={loading}
                    icon={config.icon}
                    title={item.name}
                    description={config.description}
                    price={intl.format((item.credits / 100))}
                    period={config.period}
                    features={genFeatures(item)}
                    limitations={config.limitations}
                    buttonText={config.buttonText}
                    buttonVariant={config.buttonVariant}
                    highlighted={config.highlighted}
                    current={item.name === currentPlan}
                    handleUpgrade={() => upgrade(item)}
                  />
                )
              })}
            </div>
          </div>
        </Flex>
        <Flex direction={{ initial: 'column', lg: 'row' }} justify={{ lg: 'between' }} gap="6" py="4">
          <div className="w-full lg:max-w-60">
            <Flex direction="column" gap="3">
              <Heading size="4">All features</Heading>
            </Flex>
          </div>
          <div className="w-full">
            <PlanFeatures />
          </div>
        </Flex>
      </Flex>
      <UpgradeModal isOpen={upgradeModalOpen} onOpenChange={onUpgradeModalOpenChange} plan={upgradePlanInfo} />
    </Content>
  )
}
