import { Card, Flex, Heading, Separator, Skeleton, Text, Tooltip } from '@radix-ui/themes'
import { addMonths, differenceInCalendarDays, format, startOfMonth } from 'date-fns'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import ProgressRing from '~/components/ui/progress-ring'
import { apiBillingFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'

interface History {
  amounts: number
  timestamp: number
  tokens: number
}

interface Credits { costs: number, credits: number, charges: number, qpm: number }

export function TeamBillingOverview() {
  const teamStore = useTeamStore()
  const [credits, setCredits] = useState<Credits>({
    costs: 0,
    credits: 0,
    qpm: 0,
    charges: 0,
  })
  const [history, setHistory] = useState<History[]>([])
  const [creditsLoading, setCreditsLoading] = useState(false)
  const [historyLoading, setHistoryLoading] = useState(false)
  useEffect(() => {
    if (!teamStore.currentTeam.id)
      return
    const getCreditsData = async () => {
      setCreditsLoading(true)
      try {
        const res = await apiBillingFetch(`/v1/credits/${teamStore.currentTeam.id}`)
        if (!res.data)
          return
        setCredits(res.data)
      }
      finally {
        setCreditsLoading(false)
      }
    }
    const getHistoryData = async () => {
      setHistoryLoading(true)
      try {
        const res = await apiBillingFetch(`/v1/costs/${teamStore.currentTeam.id}/history`, {
          params: {
            date_part: 'month',
            limit: 6,
          },
        })
        if (!res.data || res.data.length === 0)
          return
        setHistory(res.data.sort((a: History, b: History) => a.timestamp - b.timestamp))
      }
      finally {
        setHistoryLoading(false)
      }
    }
    getCreditsData()
    getHistoryData()
  }, [teamStore.currentTeam.id])
  const getDayjsUnitNextBilling = () => {
    const currentDate = new Date()
    const nextMonthFirstDay = startOfMonth(addMonths(currentDate, 1))
    const daysUntilNextBilling = differenceInCalendarDays(nextMonthFirstDay, currentDate)
    return daysUntilNextBilling
  }
  const historyMaxAmount = Math.max(...history.map(item => item.amounts))
  const getHistoryAmountPercentage = (amount: number) => {
    if (amount === 0)
      return 0
    return (amount / historyMaxAmount) * 100
  }
  const historyAmoutAverage = history.filter(item => item.amounts !== 0).reduce((acc, item, _, filteredArray) => acc + item.amounts / filteredArray.length, 0)
  const historyAmoutAveragePercentage = getHistoryAmountPercentage(historyAmoutAverage)
  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="row" gap="6" align="center">
          <Heading size="4" className="flex-1">
            Usage Snapshot for
            {' '}
            <Text size="4" color="teal" className="font-normal">
              {format(new Date(), 'MMMM yyyy')}
            </Text>
          </Heading>
          <Text size="2" color="teal">
            Next billing period starts in
            {' '}
            {getDayjsUnitNextBilling()}
            {' '}
            days
          </Text>
        </Flex>
        <Flex
          gap="3"
          wrap="wrap"
        >
          <Card className="w-full lg:w-80">
            <Flex direction="column" gap="4" justify="center" className="h-full">
              <Flex gap="4" justify="center">
                <div className="w-[80px]">
                  <ProgressRing current={credits.costs} max={credits.credits} size={80} />
                </div>
                <Flex gap="2" direction="column" align="start">
                  <Text size="2">Credits</Text>
                  <Skeleton loading={creditsLoading}>
                    <Text size="2" color="gray">
                      {numeral(credits.credits / 100).format('$0,0[.]00')}
                      {' '}
                      total
                    </Text>
                  </Skeleton>
                  <Skeleton loading={creditsLoading}>
                    <Text size="2" color="gray">
                      {numeral((credits.credits - credits.costs) / 100).format('$0,0[.]00')}
                      {' '}
                      remaining
                    </Text>
                  </Skeleton>
                </Flex>
              </Flex>
              <Skeleton loading={creditsLoading}>
                <Flex direction="column" gap="2">
                  <Flex gap="3">
                    <Text size="2" color="gray">Charges</Text>
                    <Text size="2" className="flex-1 text-right">{numeral(credits.charges / 100).format('$0,0[.]00')}</Text>
                  </Flex>
                  <Separator size="4" />
                  <Flex gap="3">
                    <Text size="2" color="gray">QPM</Text>
                    <Text size="2" className="flex-1 text-right">{numeral(credits.qpm).format('0,0[.]00')}</Text>
                  </Flex>
                </Flex>
              </Skeleton>
            </Flex>
          </Card>
          <Skeleton loading={historyLoading}>
            <Card className="flex-1">
              <Flex gap="4" direction="column">
                <div className="relative flex h-[140px] items-end gap-2">
                  { history.map((item) => {
                    const percentage = getHistoryAmountPercentage(item.amounts)
                    return (
                      <button
                        key={item.timestamp}
                        type="button"
                        className="flex items-end"
                        style={{ height: percentage > 0 ? `${percentage}%` : '5%' }}
                      >
                        <Tooltip content={`${format(item.timestamp * 1000, 'MMM')}: ${numeral(item.amounts / 100).format('$0,0[.]00')}`}>
                          <div
                            className="h-full w-8"
                            style={{
                              backgroundColor: percentage > 0 ? 'var(--accent-9)' : 'hsl(var(--muted))',
                            }}
                          />
                        </Tooltip>
                      </button>
                    )
                  }) }
                  <div className="absolute inset-x-0 h-px border-b border-dashed border-zinc-300 dark:border-zinc-400" style={{ bottom: `${historyAmoutAveragePercentage}%` }}>
                    <div className="flex justify-end py-1 text-xs text-zinc-300 dark:text-zinc-400">Average</div>
                  </div>
                </div>
                <Flex direction="column" gap="1">
                  <Text size="3">Monthly snapshot</Text>
                  <Text size="2" color="gray">
                    You spend roughly
                    {' '}
                    {numeral(historyAmoutAverage / 100).format('$0,0[.]000')}
                    {' '}
                    / month
                  </Text>
                </Flex>
              </Flex>
            </Card>
          </Skeleton>
        </Flex>
      </Flex>
    </Card>
  )
}
