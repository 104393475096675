import { Card, DataList, Flex, Heading, Separator, Skeleton, Text } from '@radix-ui/themes'
import { useEffect, useState } from 'react'
import CreditCard from '~/components/ui/credit-card'
import { apiBillingFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'

interface PaymentCard {
  brand: string
  last4: string
  exp_month: number
  exp_year: number
  country: string
  name: string
}

interface BillingDetails {
  address: {
    city: string
    country: string
    line1: string
    line2: string
    postal_code: string
    state: string
  }
  email: string
  name: string
}

interface PaymentItem {
  id: string
  billing_details: BillingDetails
  card: PaymentCard
  customer: {
    name: string
  }
}

export function TeamBillingPayments() {
  const teamStore = useTeamStore()
  const [payments, setPayments] = useState<PaymentItem[]>([])
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!teamStore.currentTeam.id)
      return

    const getPaymentsData = async () => {
      try {
        const res = await apiBillingFetch(`/v1/customers/${teamStore.currentTeam.id}/payment_methods`)
        if (!res.data)
          return
        setPayments([res.data[0], res.data[0]])
      }
      finally {
        setLoaded(true)
      }
    }
    getPaymentsData()
  }, [teamStore.currentTeam.id])

  const formatAddress = (address: BillingDetails['address']) => {
    const {
      line1 = '',
      line2 = '',
      city = '',
      state = '',
      postal_code = '',
      country = '',
    } = address
    const parts = [
      line1,
      line2,
      [city, state, postal_code].filter(Boolean).join(', '),
      country,
    ]
    return parts.filter(Boolean).join('\n')
  }
  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Heading size="4">
            Payments
          </Heading>
          <Text size="2" color="teal">
            Your billing address and payment methods.
          </Text>
        </Flex>
        <Skeleton loading={!loaded}>
          <Flex direction="column" gap="1">
            {payments.map((payment, index) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <div key={index}>
                  {index === 0 && <Separator my="1" size="4" />}
                  <Flex direction="column" gap="6">
                    <Flex direction={{ initial: 'column', lg: 'row' }} justify={{ lg: 'between' }} gap="6" py="4">
                      <div className="w-full lg:max-w-64">
                        <Flex direction="column" gap="3">
                          <Heading size="2">Billing address</Heading>
                          <Text size="2">The billing address will be included in your team's invoices.</Text>
                        </Flex>
                      </div>
                      <div className="w-full lg:max-w-lg">
                        <DataList.Root>
                          <DataList.Item align="center">
                            <DataList.Label minWidth="88px">Name</DataList.Label>
                            <DataList.Value>{payment.billing_details.name}</DataList.Value>
                          </DataList.Item>
                          <DataList.Item align="center">
                            <DataList.Label minWidth="88px">Contact</DataList.Label>
                            <DataList.Value>{payment.billing_details.email}</DataList.Value>
                          </DataList.Item>
                          <DataList.Item align="center">
                            <DataList.Label minWidth="88px">Address</DataList.Label>
                            <DataList.Value>{formatAddress(payment.billing_details.address)}</DataList.Value>
                          </DataList.Item>
                        </DataList.Root>
                      </div>
                    </Flex>
                    <Flex direction={{ initial: 'column', lg: 'row' }} justify={{ lg: 'between' }} gap="6" py="4">
                      <div className="w-full lg:max-w-64">
                        <Flex direction="column" gap="3">
                          <Heading size="2">Payment methods</Heading>
                          <Text size="2">When invoices are due, you will be charged automatically on your default payment method.</Text>
                        </Flex>
                      </div>
                      <div className="w-full lg:max-w-lg">
                        <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2">
                          <div className="aspect-[4/2.25] w-full max-w-xs text-left">
                            <CreditCard
                              cardNumber={payment.card.last4}
                              brand={payment.card.brand}
                              expiryDate={`${payment.card.exp_month}/${payment.card.exp_year}`}
                              cardholderName={payment.customer.name}
                            />
                          </div>
                        </div>
                      </div>
                    </Flex>
                  </Flex>
                  {index < payments.length - 1 && <Separator my="1" size="4" />}
                </div>
              )
            })}
          </Flex>
        </Skeleton>
      </Flex>
    </Card>
  )
}
