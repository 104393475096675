import { Card, Table } from '@radix-ui/themes'
import { Check, Clock, Cpu, Gauge, Globe, HeadphonesIcon, ShieldCheck, Users, X, Zap } from 'lucide-react'

const featuresConfig = {
  headers: [
    { title: 'Free Plan', price: '$0' },
    { title: 'Growth Plan', price: '$25' },
    { title: 'Enterprise Plan', price: 'Custom' },
  ],
  rows: [
    {
      feature: 'Team Collaboration',
      icon: Users,
      values: ['unlimited', 'unlimited', 'unlimited'],
    },
    {
      feature: 'LLM Bridge',
      icon: Zap,
      values: ['Chat Completion', 'Chat Completion', 'Multi-modal'],
    },
    {
      feature: 'Geo API',
      icon: Globe,
      values: ['8 regions', '8 regions', '22 regions'],
    },
    {
      feature: 'Model & Version',
      icon: Cpu,
      values: [
        'gpt-4o',
        'gpt-4o\ngemini-1.5-pro\nclaude-3.5-sonnet\nllama-3-70b-groq\nllama-3-70b-cerebras\ngpt-4',
        'Custom',
      ],
    },
    {
      feature: 'Rate Limit - RPM',
      icon: Gauge,
      values: ['6', '5,000', 'Custom'],
    },
    {
      feature: 'Rate Limit - TPM',
      icon: Gauge,
      values: ['20,000', '800,000', 'Custom'],
    },
    {
      feature: 'TPD',
      icon: Clock,
      values: ['2,000,000', '-', '-'],
    },
    {
      feature: 'Stateful Serverless GPU',
      icon: Cpu,
      values: ['-', 'Realtime Whisper (* Private Preview)', 'Custom Model Global Deployment'],
    },
    {
      feature: 'Serverless Function Calling',
      icon: Zap,
      values: ['unlimited', 'unlimited', 'unlimited'],
    },
    {
      feature: 'Function Duration',
      icon: Clock,
      values: ['unlimited', 'unlimited', 'unlimited'],
    },
    {
      feature: 'Function Executions',
      icon: Zap,
      values: ['unlimited', 'unlimited', 'unlimited'],
    },
    {
      feature: 'Function Invocations',
      icon: Zap,
      values: ['unlimited', 'unlimited', 'unlimited'],
    },
    {
      feature: 'Amount',
      icon: Gauge,
      values: ['5', 'unlimited', 'unlimited'],
    },
    {
      feature: 'Geo Deploy',
      icon: Globe,
      values: ['8 regions', '8 regions', '22 regions'],
    },
    {
      feature: 'Observability',
      icon: Gauge,
      values: ['API Access Log: 1 day', 'API Access Log: 30 days', 'custom'],
    },
    {
      feature: 'LLM Latency Breakdown',
      icon: Clock,
      values: ['yes', 'yes', 'custom'],
    },
    {
      feature: 'Support',
      icon: HeadphonesIcon,
      values: ['Email / Community', 'Email / Slack', 'Dedicated support'],
    },
    {
      feature: 'SLA',
      icon: ShieldCheck,
      values: ['99.00%', '99.90%', '99.99%'],
    },
  ],
}

export function PlanFeatures() {
  return (
    <Card>
      <Table.Root>
        <Table.Header>
          <Table.Row>
            <Table.ColumnHeaderCell className="w-1/4"></Table.ColumnHeaderCell>
            {featuresConfig.headers.map(header => (
              <Table.ColumnHeaderCell key={header.title} className="w-1/4">
                <div>{header.title}</div>
                <div>{header.price}</div>
              </Table.ColumnHeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {featuresConfig.rows.map((row) => {
            return (
              <Table.Row key={row.feature}>
                <Table.Cell>
                  <div className="flex gap-2">
                    {row.icon && <row.icon className="size-5 text-muted-foreground" />}
                    {row.feature}
                  </div>
                </Table.Cell>
                {row.values.map((value) => {
                  return (
                    <Table.Cell key={value}>
                      {value.split('\n').map(line => (
                        <span key={line} className="block">
                          {line === 'unlimited'
                            ? (
                              <Check className="size-5 text-green-500" />
                              )
                            : line === '-'
                              ? (
                                <X className="size-5 text-red-500" />
                                )
                              : (
                                  line
                                )}
                        </span>
                      ))}
                    </Table.Cell>
                  )
                })}
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </Card>
  )
}
