import { Card, DataList, Flex, Heading, Inset, Section, Text } from '@radix-ui/themes'
import { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useProjectStore } from '~/stores/project.ts'
import { Content } from '~/views/layout/Content.tsx'
import { UpdatePrompt } from '~/views/project/UpdatePrompt.tsx'
import { CodeBlock } from '~/components/CodeBlock.tsx'
import { AiChat } from '~/views/project/AiChat'

const BASE_URL = 'https://api.vivgrid.com/v1'

export function ProjectBridge() {
  const chatRef = useRef<{
    clearMessages: () => void
  }>(null)
  const [tempPrompt, setTempPrompt] = useState<string>('')
  const projectStore = useProjectStore()

  const currentProject = projectStore.currentProject
  const token = `${currentProject.appKey}.${currentProject.appSecret}`

  useEffect(() => {
    setTempPrompt(currentProject.aiPrompt)
  }, [currentProject.id, currentProject.aiPrompt])

  const clearChatMessages = () => {
    chatRef.current?.clearMessages()
  }

  return (
    <Content title="AI Bridge">
      <Helmet>
        <title>
          AI Bridge /
          {' '}
          {projectStore.currentProject.name}
        </title>
      </Helmet>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">System Prompt</Heading>
            <Text size="2" color="gray">
              Overwrite the System Prompt on the fly
            </Text>
          </Flex>
          <Flex>
            <UpdatePrompt setTempPrompt={setTempPrompt} clearChatMessages={clearChatMessages} />
            <AiChat token={token} projectId={currentProject.id} tempPrompt={tempPrompt} ref={chatRef} />
          </Flex>
        </Flex>
      </Section>

      <Section size="2" pt="0">
        <Flex direction="column" gap="4">
          <Flex direction="column" gap="1">
            <Heading size="5">Endpoint</Heading>
            <Text size="2" color="gray">
              Try our AI Bridge API in your terminal:
            </Text>
          </Flex>
          <Card>
            <DataList.Root className="gap-0">
              <DataList.Item align="center">
                <DataList.Label minWidth="88px">BASE_URL</DataList.Label>
                <DataList.Value>
                  <CodeBlock code={BASE_URL} inline />
                </DataList.Value>
              </DataList.Item>
              <DataList.Item align="center">
                <DataList.Label minWidth="88px">TOKEN</DataList.Label>
                <DataList.Value>
                  <CodeBlock code={`${currentProject.appKey}.*{${currentProject.appSecret}}*`} inline />
                </DataList.Value>
              </DataList.Item>
            </DataList.Root>
          </Card>
          <Card className="relative">
            <Inset>
              <CodeBlock
                language="bash"
                code={`curl https://api.vivgrid.com/v1/chat/completions \\\r
  -H "Content-Type: application/json" \\\r
  -H "Authorization: Bearer *{${token}}*" \\\r
  -d '{
    "model": "gpt-4o",
    "messages": [{"role": "user", "content": "once upon a time there was a lovely blue dinosaur"}],
    "temperature": 0.7,
    "stream": true
  }'`}
              />
            </Inset>
          </Card>
        </Flex>
      </Section>
    </Content>
  )
}
