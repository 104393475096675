import { Card, Flex, Heading, Link, Skeleton, Table, Text } from '@radix-ui/themes'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { apiBillingFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'

// interface BillingDetails {
//   address: {
//     city: string
//     country: string
//     line1: string
//     line2: string
//     postal_code: string
//     state: string
//   }
//   email: string
//   name: string
// }

// interface InvoiceItem {
//   billing_details: BillingDetails
// }

interface InvoiceItem {
  id: string
  invoice_pdf: string
  account_name: string
  amount_due: number
  amount_paid: number
  status: string
  number: string
  hosted_invoice_url: string
}

export function TeamBillingInvoices() {
  const teamStore = useTeamStore()
  const [invoices, setInvoices] = useState<InvoiceItem[]>([])
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (!teamStore.currentTeam.id)
      return

    const getInvoicesData = async () => {
      try {
        const customerRes = await apiBillingFetch(`/v1/customers/${teamStore.currentTeam.id}`)
        const customerId = customerRes?.data?.id
        if (!customerId)
          return
        const invoicesRes = await apiBillingFetch('/v1/invoices', {
          params: {
            customer_id: customerId,
          },
        })
        if (!invoicesRes.data)
          return
        setInvoices(invoicesRes.data)
      }
      finally {
        setLoaded(true)
      }
    }
    getInvoicesData()
  }, [teamStore.currentTeam.id])
  return (
    <Card>
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Heading size="4">
            Invoices
          </Heading>
          <Text size="2" color="teal">
            You can view your invoices here.
          </Text>
        </Flex>
        <Skeleton loading={!loaded}>
          <Table.Root>
            <Table.Header>
              <Table.Row>
                <Table.ColumnHeaderCell>Account Name</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Invoice ID</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Payable Amount</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Paid Amount</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Status</Table.ColumnHeaderCell>
                <Table.ColumnHeaderCell>Invoice</Table.ColumnHeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {invoices.map((invoice) => {
                return (
                  <Table.Row key={invoice.id}>
                    <Table.RowHeaderCell>{invoice.account_name}</Table.RowHeaderCell>
                    <Table.Cell>
                      #
                      {invoice.number}
                    </Table.Cell>
                    <Table.Cell>{numeral(invoice.amount_due / 100).format('$0,0[.]00')}</Table.Cell>
                    <Table.Cell>{numeral(invoice.amount_paid / 100).format('$0,0[.]00')}</Table.Cell>
                    <Table.Cell>{invoice.status.toLocaleUpperCase()}</Table.Cell>
                    <Table.Cell>
                      <Link href={invoice.hosted_invoice_url} target="_blank">View</Link>
                    </Table.Cell>
                  </Table.Row>
                )
              })}
            </Table.Body>
          </Table.Root>
        </Skeleton>
      </Flex>
    </Card>
  )
}
