import { Button, Card, Dialog, Flex, Text, TextField } from '@radix-ui/themes'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { mutate } from 'swr'
import { apiFetch } from '~/libs/fetcher'
import { fetchAllTeams, setCurrentTeam, useTeamStore } from '~/stores/team.ts'

export function DeleteTeam() {
  const navigate = useNavigate()
  const teamStore = useTeamStore()
  const [inputTeamName, setInputTeamName] = useState('')
  const [loading, setLoading] = useState(false)

  async function handleDelete() {
    if (teamStore.allTeams.length === 1) {
      toast.error('You must have at least one team')
      return
    }

    setLoading(true)
    try {
      const res = await apiFetch(`/teams/${teamStore.currentTeam.id}/delete`, {
        method: 'DELETE',
      })
      if (res.ok) {
        await fetchAllTeams()
        setCurrentTeam(teamStore.allTeams[0].slug)
        await mutate(['/teams', teamStore.allTeams[0].id])
        toast.success('Project deleted successfully')
        navigate(`/${teamStore.allTeams[0].slug}`)
        return
      }

      toast.error('Failed to delete project')
    }
    catch (error) {
      toast.error('Failed to delete project')
    }
    finally {
      setLoading(false)
    }
  }

  const confirmDisabled = inputTeamName !== teamStore.currentTeam.name

  return (

    <Card size="3" className="border border-destructive">
      <Flex direction="column" gap="4">
        <Flex direction="column" gap="1">
          <Text size="5" weight="bold" className="text-destructive dark:text-red-500">Delete Team</Text>
          <Text size="2" className="text-balance text-[--gray-a9]">
            Permanently delete your team and all of its contents from the platform. This action is not reversible, so please continue with caution.
          </Text>
        </Flex>

        <Flex justify="end" align="center">
          <Dialog.Root>
            <Dialog.Trigger>
              <Button className="bg-destructive text-destructive-foreground hover:bg-destructive/90">Delete Team</Button>
            </Dialog.Trigger>
            <Dialog.Content maxWidth="450px">
              <Dialog.Title>Are you absolutely sure?</Dialog.Title>
              <Dialog.Description size="2" mb="4">
                This action cannot be undone. This will permanently delete your team and remove all of its contents from our servers.
              </Dialog.Description>
              <label>
                <Flex align="center" gap="4">
                  <Text as="div" size="2" mb="1" weight="bold">
                    Team name
                  </Text>
                  <TextField.Root
                    className="flex-1"
                    value={inputTeamName}
                    placeholder={`Type "${teamStore.currentTeam.name}" to confirm`}
                    onChange={e => setInputTeamName(e.target.value)}
                  />
                </Flex>
              </label>
              <Flex gap="3" mt="4" justify="end">
                <Dialog.Close>
                  <Button variant="soft" color="gray">
                    Cancel
                  </Button>
                </Dialog.Close>
                <Button loading={loading} onClick={handleDelete} disabled={confirmDisabled} className="bg-destructive text-destructive-foreground hover:bg-destructive/90 disabled:opacity-50">Delete Team</Button>
              </Flex>
            </Dialog.Content>
          </Dialog.Root>
        </Flex>
      </Flex>
    </Card>
  )
}
