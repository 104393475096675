import { DataList, Separator } from '@radix-ui/themes'
import { useProjectStore } from '~/stores/project'
import { CodeBlock } from '~/components/CodeBlock.tsx'

const ZIPPER_ENDPOINT = 'zipper.vivgrid.com:9000'
// const OPEN_AI_BRIDGE = 'https://api.vivgrid.com'

export function BasicInfo() {
  const currentProject = useProjectStore().currentProject

  return (
    <DataList.Root className="gap-1">
      <DataList.Item align="center">
        <DataList.Label minWidth="160px">APP_ID</DataList.Label>
        <DataList.Value>
          <CodeBlock inline language="yaml" code={currentProject.appId} />
        </DataList.Value>
      </DataList.Item>
      <Separator size="4" className="col-span-2" />

      <DataList.Item align="center">
        <DataList.Label minWidth="160px">APP_KEY</DataList.Label>
        <DataList.Value>
          <CodeBlock inline language="yaml" code={currentProject.appKey} />
        </DataList.Value>
      </DataList.Item>
      <DataList.Item align="center">
        <DataList.Label minWidth="160px">APP_SECRET</DataList.Label>
        <DataList.Value>
          <CodeBlock inline language="yaml" code={`*{${currentProject.appSecret}}*`} />
        </DataList.Value>
      </DataList.Item>
      <Separator size="4" className="col-span-2" />

      <DataList.Item align="center">
        <DataList.Label minWidth="160px">ZIPPER ENDPOINT</DataList.Label>
        <DataList.Value>
          <CodeBlock inline language="yaml" code={ZIPPER_ENDPOINT} />
        </DataList.Value>
      </DataList.Item>
    </DataList.Root>
  )
}
