import { Card, Flex, Heading, Skeleton, Tabs } from '@radix-ui/themes'
import { differenceInCalendarMonths, format, startOfYear } from 'date-fns'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { Area, AreaChart, XAxis, YAxis } from 'recharts'
import { ChartContainer, ChartTooltip, ChartTooltipContent } from '~/components/ui/chart'
import { apiStorageFetch } from '~/libs/fetcher'
import { useTeamStore } from '~/stores/team'

interface TokenUsageItem {
  input_tokens: number
  output_tokens: number
  time: string
}

export function TokenUsage() {
  const quickSelection: [string, [string, number], string][] = [
    ['Today', ['day', 1], 'MMM dd'],
    ['This week', ['day', 7], 'MMM dd'],
    ['This month', ['month', 1], 'MMM dd'],
    ['Last 3 months', ['month', 3], 'MMM yyyy'],
    ['Last 6 months', ['month', 6], 'MMM yyyy'],
    ['Year to date', ['month', differenceInCalendarMonths(new Date(), startOfYear(new Date())) + 1], 'MMM yyyy'],
  ]

  const teamStore = useTeamStore()
  const [tokenUsage, setTokenUsage] = useState<TokenUsageItem[]>([])
  const [tokenUsageQuery, setTokenUsageQuery] = useState<[string, number]>(quickSelection[3][1])
  const [dateFormat, setDateFormat] = useState(quickSelection[3][2])
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!teamStore.currentTeam.id)
      return
    const getData = async () => {
      try {
        const res = await apiStorageFetch(`/v1/teams/${teamStore.currentTeam.id}/usage`, {
          params: {
            date_part: tokenUsageQuery[0],
            limit: tokenUsageQuery[1],
          },
        })
        if (!res.data)
          return
        setTokenUsage(res.data.sort((a: TokenUsageItem, b: TokenUsageItem) => +new Date(a.time) - +new Date(b.time)))
      }
      finally {
        setLoaded(true)
      }
    }
    getData()
  }, [teamStore.currentTeam.id, tokenUsageQuery])

  return (
    <Card>
      <Flex direction="column" gap="4">
        <Heading size="4">
          Token Usage explorer
        </Heading>
        <Flex gap="4">
          <Tabs.Root
            className="shrink-0 grow-0"
            defaultValue={`${tokenUsageQuery.join('$')}__${dateFormat}`}
            onValueChange={(value: string) => {
              const [usageQueryStr, dateFormat] = value.split('__')
              const [part, limit] = usageQueryStr.split('$')
              setDateFormat(dateFormat)
              setTokenUsageQuery([part, Number(limit)])
            }}
          >
            <Tabs.List className="flex-col shadow-none">
              {quickSelection.map((item) => {
                return (
                  <Tabs.Trigger key={item[0]} value={`${item[1].join('$')}__${item[2]}`}>{item[0]}</Tabs.Trigger>
                )
              })}
            </Tabs.List>
          </Tabs.Root>
          <Skeleton loading={!loaded}>
            <ChartContainer
              config={{
                input_tokens: {
                  label: 'Input',
                  color: 'var(--accent-6)',
                },
                output_tokens: {
                  label: 'Output',
                  color: 'var(--accent-9)',
                },
              }}
              className="h-80 flex-1"
            >
              <AreaChart
                data={tokenUsage}
                margin={{
                  left: 0,
                  right: 16,
                  top: 16,
                }}
              >
                <defs>
                  <linearGradient id="fillInput" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="var(--color-input_tokens)"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="var(--color-input_tokens)"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                  <linearGradient id="fillOutput" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor="var(--color-output_tokens)"
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor="var(--color-output_tokens)"
                      stopOpacity={0.1}
                    />
                  </linearGradient>
                </defs>
                <ChartTooltip
                  cursor={false}
                  labelFormatter={value => format(value, dateFormat)}
                  content={<ChartTooltipContent />}
                />
                <XAxis
                  dataKey="time"
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tickFormatter={value => format(value, dateFormat)}
                />
                <YAxis
                  tickLine={false}
                  axisLine={false}
                  tickMargin={8}
                  tickCount={3}
                  tickFormatter={value => numeral(value).format('0[.]0[0]a')}
                />
                <Area
                  isAnimationActive
                  legendType="none"
                  dataKey="input_tokens"
                  type="natural"
                  fill="url(#fillInput)"
                  fillOpacity={0.4}
                  stroke="var(--color-input_tokens)"
                  stackId="a"
                />
                <Area
                  isAnimationActive
                  legendType="none"
                  dataKey="output_tokens"
                  type="natural"
                  fill="url(#fillOutput)"
                  fillOpacity={0.4}
                  stroke="var(--color-output_tokens)"
                  stackId="a"
                />
              </AreaChart>
            </ChartContainer>
          </Skeleton>
        </Flex>
      </Flex>
    </Card>
  )
}
