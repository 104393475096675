import { Link } from 'react-router-dom'
import { Box, Flex, Grid, Text } from '@radix-ui/themes'
import { Helmet } from 'react-helmet-async'
import { UpdateTeamName } from '~/views/team/UpdateTeamName.tsx'
import { Content } from '~/views/layout/Content.tsx'
import { ManageMember } from '~/views/team/ManageMember'
import { DeleteTeam } from '~/views/team/DeleteTeam'
import { userStore } from '~/stores/user.ts'
import { teamStore } from '~/stores/team.ts'

export default function TeamSetting() {
  return (
    <Content title="Team Settings" row>
      <Helmet>
        <title>
          Team Settings
        </title>
      </Helmet>

      <Box width="15rem">
        <Grid gap="4">
          <Link to="#">
            <Text size="3" weight="medium">General</Text>
          </Link>
        </Grid>
      </Box>
      <Flex direction="column" gap="6" className="flex-1">
        <Flex direction="column" gap="6" className="flex-1">
          <UpdateTeamName />
          <ManageMember />
          {userStore.me.id === teamStore.currentTeam.ownerId && <DeleteTeam />}
        </Flex>
      </Flex>
    </Content>
  )
}
