import type { ReactNode } from 'react'
import React from 'react'

interface NodeProperties {
  className?: string[]
  style?: React.CSSProperties
  [key: string]: any
}

interface Node {
  type: string
  tagName?: keyof JSX.IntrinsicElements
  properties?: NodeProperties
  children?: Node[]
  value?: string
}

type Stylesheet = Record<string, React.CSSProperties>

function powerSetPermutations(arr: string[]): string[] {
  const arrLength = arr.length
  if (arrLength === 0 || arrLength === 1)
    return arr
  if (arrLength === 2) {
    // prettier-ignore
    return [
      arr[0],
      arr[1],
      `${arr[0]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}`,
    ]
  }
  if (arrLength === 3) {
    return [
      arr[0],
      arr[1],
      arr[2],
      `${arr[0]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}`,
      `${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}`,
    ]
  }
  if (arrLength >= 4) {
    return [
      arr[0],
      arr[1],
      arr[2],
      arr[3],
      `${arr[0]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}`,
      `${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}`,
      `${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}`,
      `${arr[2]}.${arr[3]}`,
      `${arr[3]}.${arr[0]}`,
      `${arr[3]}.${arr[1]}`,
      `${arr[3]}.${arr[2]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[1]}.${arr[3]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[2]}.${arr[3]}`,
      `${arr[0]}.${arr[3]}.${arr[1]}`,
      `${arr[0]}.${arr[3]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[1]}.${arr[2]}.${arr[3]}`,
      `${arr[1]}.${arr[3]}.${arr[0]}`,
      `${arr[1]}.${arr[3]}.${arr[2]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[0]}.${arr[3]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}`,
      `${arr[2]}.${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[3]}.${arr[0]}`,
      `${arr[2]}.${arr[3]}.${arr[1]}`,
      `${arr[3]}.${arr[0]}.${arr[1]}`,
      `${arr[3]}.${arr[0]}.${arr[2]}`,
      `${arr[3]}.${arr[1]}.${arr[0]}`,
      `${arr[3]}.${arr[1]}.${arr[2]}`,
      `${arr[3]}.${arr[2]}.${arr[0]}`,
      `${arr[3]}.${arr[2]}.${arr[1]}`,
      `${arr[0]}.${arr[1]}.${arr[2]}.${arr[3]}`,
      `${arr[0]}.${arr[1]}.${arr[3]}.${arr[2]}`,
      `${arr[0]}.${arr[2]}.${arr[1]}.${arr[3]}`,
      `${arr[0]}.${arr[2]}.${arr[3]}.${arr[1]}`,
      `${arr[0]}.${arr[3]}.${arr[1]}.${arr[2]}`,
      `${arr[0]}.${arr[3]}.${arr[2]}.${arr[1]}`,
      `${arr[1]}.${arr[0]}.${arr[2]}.${arr[3]}`,
      `${arr[1]}.${arr[0]}.${arr[3]}.${arr[2]}`,
      `${arr[1]}.${arr[2]}.${arr[0]}.${arr[3]}`,
      `${arr[1]}.${arr[2]}.${arr[3]}.${arr[0]}`,
      `${arr[1]}.${arr[3]}.${arr[0]}.${arr[2]}`,
      `${arr[1]}.${arr[3]}.${arr[2]}.${arr[0]}`,
      `${arr[2]}.${arr[0]}.${arr[1]}.${arr[3]}`,
      `${arr[2]}.${arr[0]}.${arr[3]}.${arr[1]}`,
      `${arr[2]}.${arr[1]}.${arr[0]}.${arr[3]}`,
      `${arr[2]}.${arr[1]}.${arr[3]}.${arr[0]}`,
      `${arr[2]}.${arr[3]}.${arr[0]}.${arr[1]}`,
      `${arr[2]}.${arr[3]}.${arr[1]}.${arr[0]}`,
      `${arr[3]}.${arr[0]}.${arr[1]}.${arr[2]}`,
      `${arr[3]}.${arr[0]}.${arr[2]}.${arr[1]}`,
      `${arr[3]}.${arr[1]}.${arr[0]}.${arr[2]}`,
      `${arr[3]}.${arr[1]}.${arr[2]}.${arr[0]}`,
      `${arr[3]}.${arr[2]}.${arr[0]}.${arr[1]}`,
      `${arr[3]}.${arr[2]}.${arr[1]}.${arr[0]}`,
    ]
  }
  return []
}

const classNameCombinations: Record<string, string[]> = {}

function getClassNameCombinations(classNames: string[]): string[] {
  if (classNames.length === 0 || classNames.length === 1)
    return classNames
  const key = classNames.join('.')
  if (!classNameCombinations[key])
    classNameCombinations[key] = powerSetPermutations(classNames)

  return classNameCombinations[key]
}

export function createStyleObject(
  classNames: string[],
  elementStyle: React.CSSProperties = {},
  stylesheet: Stylesheet,
): React.CSSProperties {
  const nonTokenClassNames = classNames.filter(className => className !== 'token')
  const classNamesCombinations = getClassNameCombinations(nonTokenClassNames)
  return classNamesCombinations.reduce((styleObject, className) => {
    return { ...styleObject, ...stylesheet[className] }
  }, elementStyle)
}

export function createClassNameString(classNames: string[]): string {
  return classNames.join(' ')
}

export function createChildren(
  stylesheet: Stylesheet,
  useInlineStyles: boolean,
): (children: Node[]) => ReactNode[] {
  let childrenCount = 0
  return (children) => {
    childrenCount += 1
    return children.map((child, i) =>
      createElement({
        node: child,
        stylesheet,
        useInlineStyles,
        key: `code-segment-${childrenCount}-${i}`,
      }),
    )
  }
}

export default function createElement({
  node,
  stylesheet,
  style = {},
  useInlineStyles,
  key,
}: {
  node: Node
  stylesheet: Stylesheet
  style?: React.CSSProperties
  useInlineStyles: boolean
  key: string | number
}): ReactNode {
  const { properties, type, tagName: TagName, value } = node
  if (type === 'text') {
    const parts = (value || '').split(/(\*\{.*?\}\*)/)
    return parts.map((part, index) => {
      if (part.match(/^\*\{.*\}\*$/)) {
        const content = part.slice(2, -2)
        // eslint-disable-next-line react/no-array-index-key
        return React.createElement('s', { key: index }, content)
      }
      return part
    })
    return value || null
  }
  else if (TagName) {
    const childrenCreator = createChildren(stylesheet, useInlineStyles)

    let props: React.HTMLAttributes<HTMLElement>

    if (!useInlineStyles) {
      props = {
        ...properties,
        className: createClassNameString(properties?.className || []),
      }
    }
    else {
      const allStylesheetSelectors = Object.keys(stylesheet).reduce(
        (classes, selector) => {
          selector.split('.').forEach((className) => {
            if (!classes.includes(className))
              classes.push(className)
          })
          return classes
        },
        [] as string[],
      )

      const startingClassName = properties?.className?.includes('token')
        ? ['token']
        : []

      const className
        = properties?.className
        && startingClassName.concat(
          properties.className.filter(
            className => !allStylesheetSelectors.includes(className),
          ),
        )

      props = {
        ...properties,
        className: createClassNameString(className || []),
        style: createStyleObject(
          properties?.className || [],
          { ...properties?.style, ...style },
          stylesheet,
        ),
      }
    }

    const children = childrenCreator(node.children || [])
    return React.createElement(TagName, { ...props, key }, ...children)
  }
  return null
}
